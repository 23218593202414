.projects-page {
  .project-page-title {
    font-size: 3em;
    text-align: center;
    margin: 32px auto 88px auto;
    letter-spacing: 0.05em;
  }
  .project-card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;

    .project-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 45%;
      height: 365px;
      box-shadow: 0 10px 30px #dad9df;
      border-radius: 20px;
      margin-bottom: 104px;
      overflow: hidden;
      position: relative;
      &:hover {
        img {
          opacity: 0.86;
          transform: scale(1.1);
        }
        .project-cover {
          opacity: 1;
          height: inherit;
          .project-details {
            opacity: 1;
          }
        }
      }

      img {
        width: 100%;
        -webkit-transition: all 500ms;
        transition: all 500ms;
      }

      .project-cover {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 0;
        background-color: #1c1d25da;
        -webkit-transition: all 400ms;
        transition: all 400ms;

        .project-details {
          position: absolute;
          opacity: 0;
          width: 100%;
          padding: 4em;
          color: white;
          -webkit-transition: all 400ms;
          transition: all 400ms;
          line-height: 1.5;

          .project-name {
            font-size: 2em;
            margin-bottom: 12px;
          }

          .project-tech {
            width: 70%;
            margin-bottom: 24px;
            font-size: 1.1em;
            .tools {
              margin-top: 8px;
            }
          }

          .project-desc {
            margin-bottom: 24px;
          }

          .project-card__links-container {
            display: flex;
            justify-content: space-between;
            width: 72px;

            .card-button-link {
              color: white;
              font-size: 1.4em;
              &:hover {
                color: #dadada;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .projects-page {
    .project-card-container {
      display: flex;
      justify-content: column;
      align-items: center;
      justify-content: center;
      .project-card {
        width: 90%;
        height: 380px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .projects-page {
    .project-card-container {
      .project-card {
        width: 90%;
        height: 200px;
        .project-cover {
          .project-details {
            padding: 28px;
            .project-name {
              font-size: 1em;
              margin-bottom: 4px;
            }
            .project-tech {
              font-size: 10px;
              margin-bottom: 8px;
            }
            .project-desc {
              font-size: 10px;
              margin-bottom: 16px;
            }
            .project-card__links-container {
              width: 64px;
              .card-button-link {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
