.underline-animation a:not(.contact-link) {
  position: relative;

  &:after {
    content: '';
    width: 0;
    height: 3px;
    background-color: #464c54;
    display: block;
    position: absolute;
    transition: all 400ms;
    right: 0;
  }

  &:hover::after {
    width: 100%;
    right: unset;
    left: 0;
  }
}

.footer_social-links.underline-animation a::after {
  background-color: #f4f4f4;
}
