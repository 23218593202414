.landing-page {
  display: flex;
  align-items: center;
  height: 100vh;
  margin-bottom: 160px;

  figure {
    min-width: 40%;
    margin-right: 70px;
    .landing-background--image {
      z-index: -1;
      width: 100%;
    }
  }

  // header of landing, contains name/ subtitles
  header {
    width: 50%;
    margin-left: 140px;
    height: 500px;
    .landing-name-title {
      font-size: 1.2em;
      font-family: 'Arimo', sans-serif;
      color: #878a8f;
      letter-spacing: 0.4em;
      margin-bottom: 24px;
    }
    .landing-title {
      width: 605px;
      font-size: 2em;
      line-height: 1.2;
      margin-bottom: 24px;
    }

    .blink {
      position: relative;
      bottom: 3px;
      animation: blink-animation 1s steps(5, start) infinite;
      -webkit-animation: blink-animation 1s steps(5, start) infinite;
    }
  }
  .about-container {
    width: 500px;
    .about-desc {
      line-height: 1.4;
      font-size: 1.4em;
      color: #464c54;
      margin-bottom: 12px;
    }

    .social-icons {
      margin-top: 52px;
      display: flex;
      justify-content: space-between;
      width: 400px;
      i {
        font-size: 2rem;
        -webkit-transition: all 500ms;
        transition: all 500ms;
        &:hover {
          cursor: pointer;
        }
      }
      .default-black:hover {
        color: #878a8f;
      }
      .linkedIn {
        color: #0e76a8;
        &:hover {
          color: #095274;
        }
      }
      .resume {
        color: #af170c;
        &:hover {
          color: #ee1f10;
        }
      }
    }
  }
}
.landing-bottom {
  padding-left: 100px;
  position: absolute;
  .landing-bottom--image {
    position: absolute;
    bottom: 0;
    width: 480px;
  }
  .landing-bottom--background {
    height: 40px;
    background-color: #f5f4fc;
  }
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@media only screen and (max-width: 1200px) {
  .landing-page {
    height: 680px;
    align-items: flex-start;
    header {
      margin-left: 100px;
      .landing-name-title {
        font-size: 1.4em;
      }
      .landing-title {
        width: 500px;
        font-size: 1.7em;
      }
    }
    figure {
      display: none;
    }
    .about-container {
      .about-desc {
        font-size: 1.8em;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .landing-page {
    height: 420px;
    header {
      width: 100%;
      margin-left: 0;
      padding: 0 48px;
      .landing-title {
        width: 220px;
        font-size: 16px;
      }
      .landing-name-title {
        font-size: 20px;
      }
    }
    .about-container {
      max-width: 90%;
      font-size: 10px;
      .social-icons {
        justify-content: space-between;
        max-width: 100%;
      }
    }
  }
  .landing-bottom {
    display: none;
  }
}
