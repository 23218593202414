.skills-background-overflow {
  background-color: #f5f4fc;
}
.skills-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  .skills-title {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 3em;
    letter-spacing: 0.1em;
    margin: 48px 0;
  }
  .skills-outer-container {
    display: flex;
    justify-content: space-between;
    width: 90%;
    div {
      width: 50%;
    }
    .skills-content-title {
      font-size: 2em;
      text-align: center;
      margin-bottom: 24px;
      letter-spacing: 0.1em;
    }
    .skills-content-container {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      align-items: center;
      margin: 0 auto;
      .skills {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 33.33%;
        padding-bottom: 2em;
        min-height: 150px;
        max-height: 150px;
        .image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 112px;
          &:hover {
            img {
              filter: brightness(70%);
              opacity: 0.86;
              transform: scale(0.9);
            }
            + h2 {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
        h2 {
          -webkit-transition: all 500ms;
          transition: all 500ms;
          text-align: center;
          transform: scale(0);
          opacity: 0;
          user-select: none;
        }
        img {
          -webkit-transition: all 500ms;
          transition: all 500ms;
          width: 100%;
          max-width: 75px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .skills-page {
    .skills-outer-container {
      flex-direction: column;
      align-items: center;
      div {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .skills-page {
    .skills-title {
      text-align: center;
      font-size: 3em;
      margin: 24px 24px;
    }
    .skills-outer-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        width: 100%;
      }
      .skills-content-container {
        margin-top: 40px;
        width: 100%;
        .skills {
          width: 33.33%;
        }
      }
    }
  }
}
