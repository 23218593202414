Nav {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  width: 85%;

  .nav--logo {
    -webkit-transition: all 300ms;
    transition: all 300ms;
    &:hover {
      opacity: 0.85;
    }
    .logo-container {
      display: flex;
      align-items: center;
      .logo-titles {
        h1 {
          font-size: 1.8em;
          color: #313d3f;
        }
        h2 {
          font-family: 'Noto Sans JP', sans-serif;
          font-size: 1em;
          color: #464c54;
        }
      }
      .logo-image {
        img {
          width: 100px;
          height: 70px;
        }
      }
    }
  }

  .nav__link--list {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav__link {
      font-size: 1.3rem;
      color: #464c54;
      &:hover {
        cursor: pointer;
      }
    }
    .contact-button {
      background-color: #2123ab;
      padding: 8px 20px;
      border-radius: 20px;
      -webkit-transition: all 300ms;
      transition: all 300ms;
      &:hover {
        background-color: #2c2ffc;
      }
      a {
        color: white;
        transition: opacity 300ms ease;
      }
    }
  }
}
.hamburger-menu__icon {
  display: none;
}
.hamburger__menu-item {
  text-align: center;
  background-color: #696969;
  color: antiquewhite;
  transition: 200ms ease;
  font-size: 20px;
  padding: 18px;
  letter-spacing: 1.5px;
  &:hover {
    background-color: #878a8f;
  }
}

@media only screen and (max-width: 1200px) {
  Nav {
    width: 90%;
    height: 160px;
    .nav--logo {
      .logo-container {
        .logo-titles {
          h1 {
            font-size: 2.2em;
          }
          h2 {
            font-size: 1.3em;
          }
        }
        .logo-image {
          img {
            width: 130px;
            height: 91px;
          }
        }
      }
    }
    .nav__link--list {
      display: none;
    }
  }
  .hamburger-menu__icon {
    height: 100px;
    display: flex;
    align-items: center;
    img {
      height: 40px;
      width: 40px;
    }
  }
}

@media only screen and (max-width: 600px) {
  Nav {
    height: 120px;
    .nav--logo {
      .logo-container {
        .logo-titles {
          margin: 0 24px;
          h1 {
            font-size: 20px;
          }
          h2 {
            font-size: 12px;
          }
        }
        .logo-image {
          img {
            height: 80px;
            width: 60px;
          }
        }
      }
    }
  }
  .hamburger-menu__icon {
    height: 86px;
    img {
      height: 28px;
      width: 28px;
    }
  }
}
